export default () => {
    const getHash = (): string | null => {
        if (!process.client) {
            return null;
        }

        return decodeURIComponent(window.location.hash.substring(1).replace(/=$/, ''));
    };
    const handleHash = (namespace: string, callback: (anchor: string) => void, checkAnchor = true): void => {
        if (!process.client) {
            return;
        }

        const doHandle = (): void => {
            const anchor = getHash();
            if (!anchor) {
                return;
            }
            const $anchor = document.getElementById(anchor);
            if (checkAnchor && $anchor) {
                $anchor.scrollIntoView();
            } else if (!namespace) {
                callback(anchor);
            } else if (anchor.startsWith(`${namespace}/`)) {
                callback(anchor.substring(namespace.length + 1));
            }
        };

        setTimeout(() => {
            doHandle();
            window.addEventListener('hashchange', function () {
                doHandle();
            }, false);
        }, 500);
    };
    const setHash = (namespace: string, value: string): void => {
        if (!process.client) {
            return;
        }
        const current = getHash();
        const fullValue = namespace ? `${namespace}/${value}` : value;

        if (fullValue === current) {
            return;
        }

        history.pushState(
            '',
            document.title,
            window.location.pathname + window.location.search +
                    (value ? `#${fullValue}` : ''),
        );

        if (typeof window !== 'undefined' && window.fusetag && window.fusetag.refreshSlots) {
            window.fusetag.refreshSlots();
        }
    };
    return {
        getHash,
        handleHash,
        setHash,
    };
};
